body {
  font-size: 13px !important;
  font-family: 'Spartan', sans-serif;
}

button {
  font-family: 'Josefin Sans', sans-serif !important;
}

#personAmount {
  font-size: 13px;
}

#dates {
  padding: 4px 0 0 5px !important;
  font-size: 13.7px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

p {
  margin: 0.1em 0 !important;
}

h3.ui.header {
  font-size: 15.7px !important;
  font-weight: 500;
}

h4.ui.header {
  font-size: 14px !important;
  font-weight: 500;
}

h5.ui.header {
  font-size: 12.3px !important;
  font-weight: 500;
}

.activity {
  margin-top: 0.3rem;
}

.location-selection {
  padding: 1rem 0 0.4rem 0;
  & .ui.checkbox label {
    font-size: 14px !important;
    font-weight: 500;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flex-column .ui.button {
  margin-bottom: 4px;
}

.left-aligned {
  display: flex;
  justify-content: flex-end;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.location-selection .ui.form,
.ui.header,
.ui.input > input,
.ui.form textarea,
.ui.message {
  line-height: 1.46 !important;
  font-family: 'Spartan', sans-serif !important;
}

.ui.input > input,
.ui.form input {
  border-radius: 0 !important;
}

.ui.form textarea {
  padding: 4px;
  border-radius: 0 !important;
}

.ui.input input {
  font-size: 13px !important;
  height: 30px !important;
  padding: 0.5em !important;
}

.ui.button,
.ui.basic.button {
  border-radius: 0;
  border: 1px solid #315322;
  background-color: #fff;
  color: #000;
  transition: all 0.3s;
}

.ui.basic.button,
.button-link {
  color: #555 !important;
}

.ui.button.active,
.ui.button:hover,
.ui.basic.active.button,
.ui.basic.button:hover,
.ui.basic.button:hover .button-link,
.ui.button.primary {
  background-color: #315322 !important;
  color: #fff !important;
}

.ui.button.primary:hover {
  background-color: #000 !important;
  transform: scale(1.1);
  border: 1px solid #000;
}

.ui.small.button {
  font-size: 14.5px;
}

.ui.selection {
  &.dropdown {
    min-height: 28px !important;
    padding: 0.5em !important;
  }
  &.dropdown .menu > .item {
    padding: 0.5em !important;
  }
  & .ui.accordion .accordion .title {
    padding: 0 !important;
    margin: 1em 0 0 0 !important;
  }
  &.dropdown > .dropdown.icon {
    padding: 0.5em !important;
  }
}

.ui.accordion {
  & .title {
    padding: 0 !important;
    margin: 1em 0 0 0 !important;
  }
  &:not(.styled) .accordion .title ~ .content:not(.ui),
  &:not(.styled) .title ~ .content:not(.ui) {
    padding: 0.2em !important;
  }
  & .ui.grid > .column {
    padding: 0 10px 12px 0;
  }
}

.ui.page.modals.transition.visible {
  display: flex !important;
}

.ui.message {
  padding: 0.7em !important;
}

.cottage-selector {
  font-size: 13px;
}

.ui.checkbox .box,
.ui.checkbox label {
  font-size: 13px !important;
}

.ui.grid {
  margin: 0 !important;
}

.ui.grid > .row {
  padding: 0 !important;
}

.ui.form .field {
  margin: 0.3em 0;
  & > label {
    font-weight: 500;
  }
}

.ui.popup {
  padding: 0.6em !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}

.ui.header {
  margin: 0.8em 0 0.4em 0;
}
