:root {
  --rdp-cell-size: 32px;
  --rdp-accent-color: #315322;
  --rdp-background-color: #eef5eb;
}

@mixin partly-available {
  position: absolute;
  top: -14px;
  left: -33px;
  color: #c49407;
  width: 105px;
  font-size: 11px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #555;
  line-height: 1.2;
  padding: 3px 0;
  z-index: 5;
}

@mixin partly-available--cal-only {
  top: -23px;
  padding: 1px 0;
  width: 119px;
}

.rdp-cell {
  font-size: 12px;
  & .availableUntil12,
  & .availableFrom16 {
    &:hover {
      position: relative;
      &::before {
        @include partly-available();
        content: 'Vapaa klo 12 asti';
      }
    }
    &.DayPicker-Day--selected {
      color: #fff;
      background-color: #e5ad06;
      &::before {
        @include partly-available();
        content: 'Vapaa klo 12 asti';
      }
    }
  }
  & .availableFrom16 {
    &.rdp-day.selected::before,
    &:hover::before {
      content: 'Vapaa klo 16 alkaen';
    }
  }
  & .availableUntil12,
  & .availableFrom16 {
    overflow: visible;
    &:not(.rdp-day.from):not(.rdp-day.to):not(.rdp-day_disabled) {
      background-color: #ffc107 !important;
      color: #555;
    }
  }
  & .rdp-day:not(.rdp-day_disabled):not(.to):not(.from) {
    background-color: #a8db90;
    color: #555;
    border: 1px solid #bbb;
  }
  & button {
    font-family: 'Spartan', sans-serif !important;
  }
  & .rdp-day_disabled {
    color: #b4b3b3;
    cursor: default;
    pointer-events: none;
    position: relative;
    opacity: 1 !important;
    &::before {
      position: absolute;
      content: 'X';
      left: 3px;
      font-size: 32px;
      font-weight: 700;
      color: rgb(233, 233, 233);
      opacity: 0.5;
      z-index: -1;
    }
  }
  & .rdp-day_selected {
    position: relative;
    border-width: 1px 0 1px 0 !important;
    border-color: var(--rdp-accent-color) !important;
    border-style: solid !important;
    color: #fff;
    background-color: #7dbc5e !important;
    border-radius: 100%;
    &:not(.from):not(.to) {
      background-color: #d7ffc4 !important;
      border-left: 1px solid #c4e3b6 !important;
      color: #555;
    }
    &.from {
      border-left: 1px solid var(--rdp-accent-color) !important;
    }
    &.to {
      border-right: 1px solid var(--rdp-accent-color) !important;
    }
  }
}

.calendar-only {
  .rdp-day.availableUntil12:hover::before {
    @include partly-available--cal-only();
    content: 'Vapaa klo 12 asti Available until 12';
  }
  .rdp-day.availableFrom16:hover::before {
    @include partly-available--cal-only();
    content: 'Vapaa klo 16 alkaen Available from 16';
  }
}

.rdp-day {
  border-radius: 0 !important;
}


@media only screen and (max-width: 700px) {
  .hide-mobile {
    display: none;
  }
}

@media only screen and (min-width: 701px) {
  .hide-fullscreen {
    display: none;
  }
}
